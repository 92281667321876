export const transitions = {
  REQUEST_PAYMENT: 'transition/request-payment',

  CONFIRM_PAYMENT: 'transition/confirm-payment',

  CONFIRM_PAYMENT_REVERSE: 'transition/confirm-payment-reverse',

  EXPIRE_PAYMENT: 'transition/expire-payment',

  CANCEL: 'transition/cancel',

  CANCEL_AFTER_CAPTURED: 'transition/cancel-after-captured',

  CANCEL_AFTER_CAPTURED_REVERSE: 'transition/cancel-after-captured-reverse',

  CANCEL_REVERSE: 'transition/cancel-reverse',

  CAPTURE_PAYMENT_REVERSE: 'transition/capture-payment-reverse',

  CAPTURE_PAYMENT: 'transition/capture-payment',

  AUTO_CAPTURE_PAYMENT_REVERSE: 'transition/auto-capture-payment-reverse',

  AUTO_CAPTURE: 'transition/auto-capture',

  COMPLETE: 'transition/complete',

  COMPLETE_REVERSE: 'transition/complete-reverse',
};

export const states = {
  INITIAL: 'initial',
  PENDING_PAYMENT: 'pending-payment',
  PURCHASED: 'purchased',
  PURCHASE_REVERSED: 'purchase-reversed',
  CAPTURED: 'captured',
  AUTO_CAPTURED: 'auto-captured',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'admin-purchase/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
        [transitions.CONFIRM_PAYMENT_REVERSE]: states.PURCHASE_REVERSED,
        [transitions.EXPIRE_PAYMENT]: states.EXPIRED,
      },
    },

    [states.PURCHASED]: {
      on: {
        [transitions.CANCEL]: states.CANCELED,
        [transitions.AUTO_CAPTURE]: states.AUTO_CAPTURED,
        [transitions.CAPTURE_PAYMENT]: states.AUTO_CAPTURED,
      },
    },

    [states.AUTO_CAPTURED]: {
      on: {
        [transitions.CANCEL_AFTER_CAPTURED]: states.CANCELED,
        [transitions.COMPLETE]: states.COMPLETED,
      },
    },

    [states.PURCHASE_REVERSED]: {
      on: {
        [transitions.CANCEL_REVERSE]: states.CANCELED,
        [transitions.CAPTURE_PAYMENT_REVERSE]: states.CAPTURED,
        [transitions.AUTO_CAPTURE_PAYMENT_REVERSE]: states.CAPTURED,
      },
    },

    [states.CAPTURED]: {
      on: {
        [transitions.COMPLETE_REVERSE]: states.COMPLETED,
        [transitions.CANCEL_AFTER_CAPTURED_REVERSE]: states.CANCELED,
      },
    },

    [states.EXPIRED]: {},
    [states.CANCELED]: {},

    [states.COMPLETED]: { type: 'final' },
  },
};

export const isRelevantPastTransition = transition => {
  return [
    transitions.REQUEST_PAYMENT,
    transitions.CONFIRM_PAYMENT,
    transitions.CANCEL,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return [].includes(transition);
};

export const isProviderReview = transition => {
  return [].includes(transition);
};

export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT].includes(transition);
};

export const isCompleted = transition => {
  const txCompletedTransitions = [transitions.COMPLETE, transitions.COMPLETE_REVERSE];
  return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.CANCEL,
    transitions.CANCEL_REVERSE,
    transitions.EXPIRE_PAYMENT,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [];
