export const transitions = {
  INQUIRE: 'transition/inquire',

  SENT_QUOTE: 'transition/sent-quote',

  EXPIRE_INQUIRE: 'transition/expire-inquire',

  EXPIRE_QUOTE: 'transition/expire-quote',

  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  DECLINE_QUOTE: 'transition/decline-quote',

  EDIT_QUOTE: 'transition/edit-quote',

  CONFIRM_PAYMENT: 'transition/confirm-payment',

  AUTO_CAPTURE: 'transition/auto-capture',

  CANCEL_AFTER_CAPTURE_BY_PROVIDER: 'transition/cancel-after-capture-by-provider',

  CONFIRM_PURCHASE: 'transition/confirm-purchase',

  CONFIRM_PURCHASE_AFTER_CAPTURE: 'transition/confirm-purchase-after-capture',

  MARK_DELIVERED: 'transition/mark-delivered',

  OPERATOR_MARK_DELIVERED: 'transition/operator-mark-delivered',

  CANCEL: 'transition/cancel',

  AUTO_CANCEL: 'transition/auto-cancel',

  MARK_RECEIVED: 'transition/mark-received',

  AUTO_MARK_RECEIVED: 'transition/auto-mark-received',

  DISPUTE: 'transition/dispute',

  OPERATOR_DISPUTE: 'transition/operator-dispute',

  MARK_RECEIVED_FROM_DISPUTED: 'transition/mark-received-from-disputed',

  CANCEL_FROM_DISPUTED: 'transition/cancel-from-disputed',

  AUTO_CANCEL_FROM_DISPUTED: 'transition/auto-cancel-from-disputed',

  AUTO_COMPLETE: 'transition/auto-complete',

  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};

export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  INQUIRE_EXPIRED: 'inquire-expired',
  PENDING_PAYMENT: 'pending-payment',
  QUOTE_DECLINED: 'quote-declined',
  AUTO_CAPTURED: 'auto-captured',
  PURCHASE_CONFIRMED: 'purchase-confirmed',
  PURCHASED: 'purchased',
  DELIVERED: 'delivered',
  RECEIVED: 'received',
  DISPUTED: 'disputed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'reverse-purchase/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.SENT_QUOTE]: states.INQUIRY,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.EXPIRE_INQUIRE]: states.INQUIRE_EXPIRED,
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
        [transitions.DECLINE_QUOTE]: states.QUOTE_DECLINED,
        [transitions.EDIT_QUOTE]: states.INQUIRY,
      },
    },

    [states.QUOTE_DECLINED]: {
      on: {
        [transitions.EDIT_QUOTE]: states.INQUIRY,
        [transitions.EXPIRE_QUOTE]: states.INQUIRE_EXPIRED,
      }
    },

    [states.INQUIRE_EXPIRED]: {},

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED,
      },
    },

    [states.PURCHASED]: {
      on: {
        [transitions.CONFIRM_PURCHASE]: states.PURCHASE_CONFIRMED,
        [transitions.AUTO_CAPTURE]: states.AUTO_CAPTURED,
        [transitions.CANCEL]: states.CANCELED,
        [transitions.AUTO_CANCEL]: states.CANCELED,
      },
    },

    [states.AUTO_CAPTURED]: {
      on: {
        [transitions.CONFIRM_PURCHASE_AFTER_CAPTURE]: states.PURCHASE_CONFIRMED,
        [transitions.CANCEL_AFTER_CAPTURE_BY_PROVIDER]: states.CANCELED,
      },
    },

    [states.PURCHASE_CONFIRMED]: {
      on: {
        [transitions.MARK_DELIVERED]: states.DELIVERED,
        [transitions.OPERATOR_MARK_DELIVERED]: states.DELIVERED,
      },
    },

    [states.CANCELED]: {},

    [states.DELIVERED]: {
      on: {
        [transitions.MARK_RECEIVED]: states.RECEIVED,
        [transitions.AUTO_MARK_RECEIVED]: states.RECEIVED,
        [transitions.DISPUTE]: states.DISPUTED,
        [transitions.OPERATOR_DISPUTE]: states.DISPUTED,
      },
    },

    [states.DISPUTED]: {
      on: {
        [transitions.MARK_RECEIVED_FROM_DISPUTED]: states.RECEIVED,
        [transitions.CANCEL_FROM_DISPUTED]: states.CANCELED,
        [transitions.AUTO_CANCEL_FROM_DISPUTED]: states.CANCELED,
      },
    },

    [states.RECEIVED]: {
      on: {
        [transitions.AUTO_COMPLETE]: states.COMPLETED,
      },
    },

    [states.COMPLETED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

export const isRelevantPastTransition = transition => {
  return [
    transitions.INQUIRE,
    transitions.SENT_QUOTE,
    transitions.DECLINE_QUOTE,
    transitions.EDIT_QUOTE,
    transitions.CONFIRM_PAYMENT,
    transitions.AUTO_CANCEL,
    transitions.EXPIRE_QUOTE,
    transitions.CANCEL,
    transitions.CANCEL_AFTER_CAPTURE_BY_PROVIDER,
    transitions.CONFIRM_PURCHASE,
    transitions.CONFIRM_PURCHASE,
    transitions.CONFIRM_PURCHASE_AFTER_CAPTURE,
    transitions.MARK_DELIVERED,
    transitions.OPERATOR_MARK_DELIVERED,
    transitions.DISPUTE,
    transitions.OPERATOR_DISPUTE,
    transitions.AUTO_COMPLETE,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT_AFTER_INQUIRY].includes(transition);
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.AUTO_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.CANCEL,
    transitions.AUTO_CANCEL,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];
