export const SELL_LISTING_TYPE = 'sell-products';
export const BUY_LISTING_TYPE = 'buy-products';

export const isSellListingType = listing => {
  if (!listing) return false;

  return listing?.attributes?.publicData?.listingType === SELL_LISTING_TYPE;
};

export const isBuyListingType = listing => {
  if (!listing) return false;

  return listing?.attributes?.publicData?.listingType === BUY_LISTING_TYPE;
};

export const isHiddenListing = listing => {
  if (!listing) return false;

  return !!listing?.attributes?.publicData?.Is_hidden;
};
