import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FacebookIcon, WhatsappIcon } from 'react-share';

import css from './SocialSharePopup.module.css';

const SocialShareIcons = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.rootIcon, className);

  if (type === 'copy') {
    return (
      <svg
        className={css.copy}
        viewBox="0 0 36 36"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        version="1.1"
        id="svg8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs12" />
        <g id="g6">
          <circle
            cx="18"
            cy="18"
            r="17.5"
            stroke="#E7E7E7"
            fill="#F4F4F4"
            stroke-width=".5"
            id="circle2"
          />
          <path
            d="m21.41,23.29l-0.71,-0.71l4.59,-4.58l-4.59,-4.59l0.71,-0.71l5.3,5.3l-5.3,5.29zm-6.12,-0.7l-4.58,-4.59l4.59,-4.59l-0.71,-0.7l-5.3,5.29l5.29,5.29l0.71,-0.7z"
            fill="#606060"
            id="path4"
          />
        </g>
      </svg>
    );
  } else if (type === 'share') {
    return (
      <svg
        className={css.share}
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          fill="#201F22"
          fillRule="evenodd"
          d="M14.74 3.038a1.875 1.875 0 00-1.713 2.638.659.659 0 01.049.101 1.875 1.875 0 101.664-2.739zm-3.125 1.875c0 .27.034.531.098.78L7.218 8.01a3.125 3.125 0 100 3.808l4.495 2.316a3.128 3.128 0 003.027 3.905 3.125 3.125 0 10-2.478-5.029l-4.495-2.315a3.13 3.13 0 000-1.561l4.495-2.316a3.125 3.125 0 10-.647-1.904zm-5.162 5.765a1.868 1.868 0 00-.047-1.626 1.875 1.875 0 100 1.723.629.629 0 01.047-.097zm6.574 3.473a.615.615 0 00.049-.101 1.875 1.875 0 11-.05.101z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  } else if (type === 'email') {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 122 88"
        enableBackground="new 0 0 122 88"
      >
        <g>
          <path d="M7.048,0h108.784c1.939,0,3.701,0.794,4.977,2.069c1.277,1.277,2.07,3.042,2.07,4.979v74.759 c0,1.461-0.451,2.822-1.221,3.951c-0.141,0.365-0.361,0.705-0.662,0.994c-0.201,0.189-0.422,0.344-0.656,0.461 c-1.225,1.021-2.799,1.643-4.508,1.643H7.048c-1.937,0-3.701-0.793-4.979-2.07C0.794,85.51,0,83.748,0,81.807V7.048 c0-1.941,0.792-3.704,2.068-4.979C3.344,0.792,5.107,0,7.048,0L7.048,0z M5.406,78.842l38.124-38.22L5.406,9.538V78.842 L5.406,78.842z M47.729,44.045L8.424,83.449h105.701L76.563,44.051L64.18,54.602l0,0c-0.971,0.83-2.425,0.877-3.453,0.043 L47.729,44.045L47.729,44.045z M80.674,40.549l36.799,38.598V9.198L80.674,40.549L80.674,40.549z M8.867,5.406l53.521,43.639 l51.223-43.639H8.867L8.867,5.406z" />
        </g>
      </svg>
    );
  } else if (type === 'whatsApp') {
    return <WhatsappIcon className={classes} />;
  } else if (type === 'messenger') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <radialGradient id="a" cx="19.25%" cy="99.45%" r="108.96%">
          <stop offset="0" stopColor="#09f"></stop>
          <stop offset="0.61" stopColor="#a033ff"></stop>
          <stop offset="0.93" stopColor="#ff5280"></stop>
          <stop offset="1" stopColor="#ff7061"></stop>
        </radialGradient>
        <g fill="none">
          <path
            fill="url(#a)"
            d="M12 0C5.24 0 0 4.95 0 11.64c0 3.5 1.43 6.52 3.77 8.6.2.18.31.43.32.7l.06 2.13a.96.96 0 001.35.85l2.38-1.05c.2-.1.43-.1.65-.05 1.1.3 2.25.46 3.47.46 6.76 0 12-4.95 12-11.64C24 4.96 18.76 0 12 0z"
            transform="translate(4 4)"
          ></path>
          <path
            fill="#fff"
            d="M8.8 19.04l3.52-5.59a1.8 1.8 0 012.6-.48l2.8 2.1c.27.2.62.2.88 0l3.78-2.87c.5-.39 1.17.22.83.76l-3.53 5.59a1.8 1.8 0 01-2.6.48l-2.8-2.1a.72.72 0 00-.87 0L9.6 19.8c-.5.38-1.15-.22-.82-.76z"
          ></path>
        </g>
      </svg>
    );
  } else if (type === 'facebook') {
    return <FacebookIcon className={classes} />;
  } else if (type === 'twitter') {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={classes}
        ariaHidden="true"
        display="block"
        viewBox="0 0 32 32"
      >
        <path fill="#1DA1F2" d="M0 0h32v32H0z"></path>
        <path
          fill="#FFF"
          d="M18.66 7.99a4.5 4.5 0 00-2.28 4.88A12.31 12.31 0 017.3 8.25a4.25 4.25 0 001.38 5.88c-.69 0-1.38-.13-2-.44a4.54 4.54 0 003.5 4.31 4.3 4.3 0 01-2 .06 4.64 4.64 0 004.18 3.13A8.33 8.33 0 015.82 23a12.44 12.44 0 0019.31-11.19 7.72 7.72 0 002.2-2.31 8.3 8.3 0 01-2.5.75 4.7 4.7 0 002-2.5c-.88.5-1.82.88-2.82 1.06A4.5 4.5 0 0018.66 8z"
        ></path>
      </svg>
    );
  } else if (type === 'messages') {
    return (
      <svg
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        fill="#000000"
        ariaHidden="true"
        display="block"
        width="32"
        height="32"
      >
        <g strokeWidth="0"></g>
        <g strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <g transform="translate(5.796 8.927)">
            <linearGradient
              id="a"
              gradientUnits="userSpaceOnUse"
              x1="-1183.897"
              y1="1855.346"
              x2="-1119.897"
              y2="1855.346"
              gradientTransform="matrix(0 1 1 0 -1829.142 1174.97)"
            >
              <stop offset="0" stopColor="#00ea66"></stop>
              <stop offset="1" stopColor="#00d50f"></stop>
            </linearGradient>
            <path
              d="M58.204 6.073v34c0 8.284-6.716 15-15 15h-34c-8.284 0-15-6.716-15-15v-34c0-8.284 6.716-15 15-15h34c8.284 0 15 6.716 15 15z"
              fill="url(#a)"
            ></path>
            <g transform="translate(-10.184 -993.295)">
              <linearGradient
                id="b"
                gradientUnits="userSpaceOnUse"
                x1="-904.919"
                y1="1071.927"
                x2="-904.919"
                y2="1092.493"
                gradientTransform="matrix(.794 0 0 -.8101 754.868 1899.425)"
              >
                <stop offset="0" stopColor="#e5f5d9"></stop>
                <stop offset="1" stopColor="#ffffff"></stop>
              </linearGradient>
              <path
                d="M36.4 1001.368c-3.512 0-6.761.908-9.47 2.473-.459.265-.91.531-1.335.832-.41.292-.786.611-1.162.934-.014.011-.036.013-.05.025-3.076 2.637-4.995 6.271-4.995 10.294 0 3.965 1.874 7.541 4.872 10.167.014.107.024.223.024.303 0 2.322-1.786 4.214-4.055 4.239a9.41 9.41 0 0 0 3.61.731c2.19 0 4.163-.814 5.762-2.094a19.415 19.415 0 0 0 6.8 1.236c9.388 0 16.988-6.535 16.988-14.583s-7.601-14.557-16.989-14.557z"
                fill="url(#b)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  } else if (type === 'embed') {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="32px"
        height="32px"
        viewBox="0 0 122.88 101.57"
        enableBackground="new 0 0 122.88 101.57"
      >
        <g>
          <path d="M44.97,12.84h-17.2L0,49.37L27.77,85.9h17.2L17.2,49.37L44.97,12.84L44.97,12.84z M77.91,12.84h17.2l27.77,36.53 L95.11,85.9h-17.2l27.77-36.53L77.91,12.84L77.91,12.84z M70.17,0.04l5.96,1.39c0.94,0.22,1.52,1.16,1.31,2.1l-22.5,96.69 c-0.22,0.93-1.16,1.52-2.1,1.31l-5.95-1.39c-0.94-0.22-1.52-1.16-1.31-2.1l22.5-96.69C68.3,0.42,69.24-0.17,70.17,0.04L70.17,0.04 L70.17,0.04z" />
        </g>
      </svg>
    );
  } else if (type === 'pinterest') {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 455.731 455.731"
        xmlSpace="preserve"
      >
        <path fill="#C9353D" d="M0 0H455.731V455.731H0z"></path>
        <path
          fill="#FFF"
          d="M160.601 382C86.223 350.919 37.807 262.343 68.598 172.382 99.057 83.391 197.589 36.788 286.309 69.734c88.972 33.04 132.978 131.213 98.486 219.22-35.709 91.112-131.442 123.348-203.22 100.617 5.366-13.253 11.472-26.33 15.945-39.943 4.492-13.672 7.356-27.878 10.725-41.037 2.9 2.44 5.814 5.027 8.866 7.439 15.861 12.535 33.805 13.752 52.605 9.232 19.977-4.803 35.764-16.13 47.455-32.78 19.773-28.16 26.751-60.019 21.972-93.546-4.942-34.668-25.469-59.756-57.65-72.389-48.487-19.034-94.453-12.626-134.269 22.259-30.622 26.83-40.916 72.314-26.187 107.724 5.105 12.274 13.173 21.907 25.379 27.695 6.186 2.933 8.812 1.737 10.602-4.724.133-.481.295-.955.471-1.422 3.428-9.04 2.628-16.472-3.473-25.199-11.118-15.906-9.135-34.319-3.771-51.961 10.172-33.455 40.062-55.777 75.116-56.101 9.39-.087 19.056.718 28.15 2.937 27.049 6.599 44.514 27.518 46.264 55.253 1.404 22.242-2.072 43.849-11.742 64.159-4.788 10.055-11.107 18.996-20.512 25.325-8.835 5.945-18.496 8.341-28.979 5.602-14.443-3.774-22.642-16.95-18.989-31.407 3.786-14.985 8.685-29.69 12.399-44.69 1.57-6.344 2.395-13.234 1.751-19.696-1.757-17.601-18.387-25.809-33.933-17.216-10.889 6.019-16.132 16.079-18.564 27.719-2.505 11.992-1.292 23.811 2.61 35.439.784 2.337.9 5.224.347 7.634-7.063 30.799-14.617 61.49-21.306 92.369-1.952 9.011-1.59 18.527-2.239 27.815-.123 1.778-.017 3.574-.017 5.939z"
        ></path>
      </svg>
    );
  } else if (type === 'telegram') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={classes}>
        <rect width="512" height="512" fill="#37aee2" rx="0"></rect>
        <path fill="#c8daea" d="M199 404c-11 0-10-4-13-14l-32-105 245-144"></path>
        <path fill="#a9c9dd" d="M199 404c7 0 11-4 16-8l45-43-56-34"></path>
        <path
          fill="#f6fbfe"
          d="M204 319l135 99c14 9 26 4 30-14l55-258c5-22-9-32-24-25L79 245c-21 8-21 21-4 26l83 26 190-121c9-5 17-3 11 4"
        ></path>
      </svg>
    );
  } else {
    return null;
  }
};

SocialShareIcons.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SocialShareIcons.propTypes = {
  rootClassName: string,
  className: string,
};

export default SocialShareIcons;
