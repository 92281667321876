import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { FormattedMessage } from '../../util/reactIntl';
import { Modal } from '../../components';
import SocialShareIcons from './SocialShareIcons';
import { createSlug } from '../../util/urlHelpers';

import css from './SocialSharePopup.module.css';
import { isBuyListingType } from '../../util/listingHelpers';

export const SocialSharePopup = props => {
  const { rootClassName, className, onManageDisableScrolling, listing, intl, isSearchPage } = props;

  listing.images = listing?.images?.slice(0, 1) || null;

  const listingTitle = listing?.attributes?.title;

  const classes = classNames(rootClassName || css.root, className);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const isBuyListing = isBuyListingType(listing);

  const type = isBuyListing ? 'Help me buy:' : 'Offering to buy:';

  const shareLink =
    typeof window !== 'undefined'
      ? `${window.location.origin}/l/${createSlug(listingTitle || '')}/${listing?.id?.uuid}`
      : '';

  const listingTitleWithType = `${type} ${listingTitle}`;

  const handleCopyToClipboard = e => {
    e.preventDefault();
    e.stopPropagation();

    navigator.clipboard.writeText(shareLink);
    setShowSuccessPopup(true);
    setTimeout(() => setShowSuccessPopup(false), 2000);
  };

  const handleCloseModal = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    }
  };

  const onOpen = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsPopupOpen(true);
  };

  return (
    <div
      className={classes}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={css.shareWrapper} onClick={onOpen}>
        <SocialShareIcons type="share" />
      </div>

      <Modal
        id="SocialSharePopup"
        isOpen={isPopupOpen}
        onClose={handleCloseModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={' '}
      >
        <>
          <h3>
            <FormattedMessage id="SocialSharePopup.shareThisListing" />
          </h3>
          <div className={css.shareList}>
            <div
              className={classNames(css.shareListItem, css.shareListItemText)}
              onClick={handleCopyToClipboard}
            >
              <div className={classNames(css.shareItem, css.copyIcon)}>
                <SocialShareIcons type="copy" />
                {showSuccessPopup ? (
                  <FormattedMessage id="SocialSharePopup.copied" />
                ) : (
                  <FormattedMessage id="SocialSharePopup.copy" />
                )}
              </div>
            </div>

            <div className={css.shareListItem}>
              <WhatsappShareButton
                className={css.circle}
                url={intl.formatMessage({ id: 'SocialSharePopup.checkThisLink' }, { shareLink })}
                resetButtonStyle={false}
                separator={"\n"}
                title={listingTitleWithType}
              >
                <div className={css.shareItem}>
                  <SocialShareIcons type="whatsApp" />
                  <FormattedMessage id="SocialSharePopup.whatsApp" />
                </div>
              </WhatsappShareButton>
            </div>

            <div className={css.shareListItem}>
              <FacebookShareButton
                className={css.circle}
                url={shareLink}
                resetButtonStyle={false}
              >
                <div className={css.shareItem}>
                  <SocialShareIcons type="facebook" />
                  <FormattedMessage id="SocialSharePopup.facebook" />
                </div>
              </FacebookShareButton>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

SocialSharePopup.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SocialSharePopup.propTypes = {
  rootClassName: string,
  className: string,
};
