import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconLike.module.css';

const IconLike = props => {
  const { onClick, rootClassName, className } = props;

  const classes = classNames(rootClassName || css.rootIcon, className);

  return (
    <div onClick={onClick} className={css.root}>
      <svg
        className={classes}
        width="20"
        height="20"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.154 12.2332L24 13.5755L24.846 12.2332C26.8589 9.03947 30.4082 7 34.4545 7C40.7932 7 46 12.2432 46 18.6667C46 21.5353 44.6926 24.496 42.5971 27.3811C40.509 30.2561 37.7033 32.9661 34.8631 35.3124C32.0277 37.6546 29.1874 39.6104 27.0532 40.9816C25.9871 41.6666 25.0996 42.2042 24.4801 42.5697C24.2963 42.6782 24.1361 42.7715 24.0019 42.8489C23.8675 42.7708 23.707 42.6766 23.5226 42.567C22.903 42.1988 22.0154 41.6574 20.9491 40.9682C18.8146 39.5886 15.9738 37.6228 13.1379 35.2737C10.2972 32.9205 7.49097 30.2067 5.40239 27.3359C3.30594 24.4543 2 21.5073 2 18.6667C2 12.2432 7.20678 7 13.5455 7C17.5918 7 21.1411 9.03947 23.154 12.2332Z"
          stroke="#ec454c"
          fill="none"
          stroke-width="2"
        />
      </svg>
    </div>
  );
};

IconLike.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLike.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLike;
