import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconLikeFilled.module.css';

const IconLikeFilled = props => {
  const { onClick, rootClassName, className } = props;

  const classes = classNames(rootClassName || css.rootIcon, className);

  return (
    <div onClick={onClick} className={css.root}>
      <svg
        className={classes}
        width="20"
        height="20"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.4545 6C30.0636 6 26.1955 8.21667 24 11.7C21.8045 8.21667 17.9364 6 13.5455 6C6.64545 6 1 11.7 1 18.6667C1 31.2278 24 44 24 44C24 44 47 31.3333 47 18.6667C47 11.7 41.3545 6 34.4545 6Z"
          fill="#ec454c"
        />
      </svg>
    </div>
  );
};

IconLikeFilled.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLikeFilled.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLikeFilled;
