import React from 'react';
import IconLike from '../IconLike/IconLike';
import IconLikeFilled from '../IconLikeFilled/IconLikeFilled';

import css from './Likes.module.css';

export const Likes = ({ listing, onLikeListing, onUnlikeListing, hideCounter }) => {
  if (!listing) return null;

  return (
    <div
      className={css.likeContainer}
      onClick={
        listing.attributes.liked ? onUnlikeListing(listing.id.uuid) : onLikeListing(listing.id.uuid)
      }
    >
      {listing.attributes.liked ? <IconLikeFilled /> : <IconLike />}
      {!hideCounter ? <span>{listing.attributes.likes || 0}</span> : null}
    </div>
  );
};
