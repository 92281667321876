import {
  updatedEntities,
  denormalisedEntities,
  denormalisedResponseEntities,
} from '../../util/data';
import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import {
  likeListingRequest as likeListingRequestApi,
  unlikeListingRequest as unlikeListingRequestApi,
  getFavouriteListingsRequest as getFavouriteListingsRequestApi,
} from '../../util/api';
import { changeListingMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

const crateAction = type => {
  const page = 'app/ManageFavouritesPage/';

  return {
    request: `${page}${type}_REQUEST`,
    success: `${page}${type}_SUCCESS`,
    error: `${page}${type}_ERROR`,
  };
};

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/ManageListingsPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/ManageListingsPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/ManageListingsPage/FETCH_LISTINGS_ERROR';

export const LIKE_LISTING_ACTION = 'LIKE_LISTING';
export const UNLIKE_LISTING_ACTION = 'UNLIKE_LISTING';
export const GET_FAVOURITES_LISTINGS_ACTION = 'GET_FAVOURITES_LISTINGS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],

  likeInProgress: false,
  likeError: null,

  unlikeInProgress: false,
  unlikeError: null,
};

const manageFavouritesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResults: denormalisedResponseEntities(payload),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case crateAction(LIKE_LISTING_ACTION).request:
      return {
        ...state,
        likeInProgress: true,
        likeError: null,
      };
    case crateAction(LIKE_LISTING_ACTION).success:
      return {
        ...state,
        likeInProgress: false,
        likeError: null,
      };
    case crateAction(LIKE_LISTING_ACTION).error:
      return {
        ...state,
        likeInProgress: false,
        likeError: payload,
      };

    case crateAction(UNLIKE_LISTING_ACTION).request:
      return {
        ...state,
        unlikeInProgress: true,
        unlikeError: null,
      };
    case crateAction(UNLIKE_LISTING_ACTION).success:
      return {
        ...state,
        unlikeInProgress: false,
        unlikeError: null,
      };
    case crateAction(UNLIKE_LISTING_ACTION).error:
      return {
        ...state,
        unlikeInProgress: false,
        unlikeError: payload,
      };

    default:
      return state;
  }
};

export default manageFavouritesPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const likeListingRequest = () => ({
  type: crateAction(LIKE_LISTING_ACTION).request,
});

export const likeListingSuccess = payload => ({
  type: crateAction(LIKE_LISTING_ACTION).success,
  payload,
});

export const likeListingError = e => ({
  type: crateAction(LIKE_LISTING_ACTION).error,
  error: true,
  payload: e,
});

export const unlikeListingRequest = () => ({
  type: crateAction(UNLIKE_LISTING_ACTION).request,
});

export const unlikeListingSuccess = payload => ({
  type: crateAction(UNLIKE_LISTING_ACTION).success,
  payload,
});

export const unlikeListingError = e => ({
  type: crateAction(UNLIKE_LISTING_ACTION).error,
  error: true,
  payload: e,
});

export const getFavouritesListingsRequest = () => ({
  type: crateAction(GET_FAVOURITES_LISTINGS_ACTION).request,
});

export const getFavouritesListingsSuccess = payload => ({
  type: crateAction(GET_FAVOURITES_LISTINGS_ACTION).success,
  payload,
});

export const getFavouritesListingsError = e => ({
  type: crateAction(GET_FAVOURITES_LISTINGS_ACTION).error,
  error: true,
  payload: e,
});

export const queryListings = queryParams => async (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  const { perPage, page, ...rest } = queryParams;
  const params = { ...rest, perPage };

  let currentUser = getState()?.user?.currentUser;

  if (!currentUser) {
    await dispatch(fetchCurrentUser());

    currentUser = getState()?.user?.currentUser;
  }

  const { data, meta } = await dispatch(
    getFavouritesListing({ userId: currentUser?.id?.uuid, page })
  );

  const ids = Object.keys(data);

  return sdk.listings
    .query({ ...params, ids })
    .then(response => {
      response.data.meta = meta;

      dispatch(queryListingsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(queryListingsError(storableError(e)));
      throw e;
    });
};

const updateListingLikeCounter = (getState, listingId, action) => {
  const { entities } = getState().marketplaceData;
  const listings = entities.listing;

  const changedListing = () => {
    const listing = listings[listingId];

    if (!listing) return;

    switch (action) {
      case 'like': {
        listing.attributes.likes += 1;
        listing.attributes.liked = true;
        return { [listingId]: listing };
      }
      case 'unlike': {
        listing.attributes.likes -= 1;
        listing.attributes.liked = false;
        return { [listingId]: listing };
      }

      default:
        return { [listingId]: listing };
    }
  };

  return changedListing();
};

export const likeListing = (listingId, userId) => (dispatch, getState, sdk) => {
  dispatch(likeListingRequest());

  likeListingRequestApi({ listingId, userId })
    .then(res => {
      dispatch(likeListingSuccess(res));

      const updatedListing = updateListingLikeCounter(getState, listingId, 'like');

      dispatch(changeListingMarketplaceEntities(updatedListing));
    })
    .catch(e => dispatch(likeListingError(e)));
};

export const unlikeListing = (listingId, userId) => async (dispatch, getState, sdk) => {
  dispatch(unlikeListingRequest());

  await unlikeListingRequestApi({ listingId, userId })
    .then(res => {
      const updatedListing = updateListingLikeCounter(getState, listingId, 'unlike');

      dispatch(changeListingMarketplaceEntities(updatedListing));
      dispatch(unlikeListingSuccess(res));
    })
    .catch(e => dispatch(unlikeListingError(e)));
};

export const getFavouritesListing = userId => (dispatch, getState, sdk) => {
  dispatch(getFavouritesListingsRequest());

  return getFavouriteListingsRequestApi(userId)
    .then(res => {
      dispatch(getFavouritesListingsSuccess(res));
      return res;
    })
    .catch(e => dispatch(getFavouritesListingsError(e)));
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images', 'currentStock'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
